<template>
	
    <div class="corporateInformation-wrapper">
		  <!-- <a target="_blank" v-if="form.appsecret" :href="url">{{$t('refresh token')}}</a>  -->
        <el-row :gutter="12">
            <el-col :xs="24" :span="24" :lg="18">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{$t('modifyInformation')}}</span>
                    </div>
                    <el-form :model="form">
                        <el-form-item :label="item.label" v-for="item in formConfig" :key="item.prop">
                            <el-input v-model="form[item.prop]" clearable></el-input>
                        </el-form-item>
                    </el-form>
                      <el-button type="primary" @click="changeInfo" class="btn">{{$t('submit')}}</el-button>
                </el-card>
            </el-col>
        </el-row>
		<div style="height: 30px;"></div>
        <el-row :gutter="12">
            <el-col :xs="24" :span="24" :lg="18">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>接入信息</span>
                    </div>
                    <el-form :model="form">
                        <el-form-item label="接入地址"  >
                            <el-input  v-model="linkurl" clearable></el-input>
                        </el-form-item>
						<el-form-item label="Token"  >
                            <el-input v-model="linktoken"  clearable></el-input>
                        </el-form-item>
                    </el-form>
                      
                </el-card>
            </el-col>
        </el-row>
	 
    </div>
</template>
<script>
import {changeUserinfo} from '@/api'
import userinfo from '@/mixins/userinfo'
export default {
    mixins : [userinfo],
    data() {
        return {
			linkurl:'',
			linktoken:'mytokenhk',
			url:'',
            formConfig: [
                {label: this.$t('CompanyName'), prop: 'name', type: 'input'},
                {label: this.$t('contactNumber'), prop: 'mobile', type: 'input'},
                {label: this.$t('contactAddress'), prop: 'address', type: 'input'},
                {label: this.$t('appid'), prop: 'appid', type: 'input'},
                {label: this.$t('appsecret'), prop: 'appsecret', type: 'input'},
                {label: this.$t('fromphone'), prop: 'fromphone', type: 'input'},
                {label: this.$t('access_token'), prop: 'access_token', type: 'input'}
            ],
            form: {
                name: '',appid:'',appsecret:'',fromphone:'',
                mobile: '',
                address: ''
            },
        }
    },
    created() {
     
        var user = JSON.parse(window.localStorage.getItem('user'))
		console.log('user:',user);
	
        this.form.username = user.username
        this.form.password = user.password
        this.form.fromphone = user.fromphone
        this.form.appid = user.appid
        this.form.appsecret = user.appsecret
        this.getUserinfo()
    },
    watch: {
        userinfo(val) {
            console.log('val',val)
			this.url	 		 ='https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=shouquan_whatsapp&state='+val.id
            this.linkurl		 = 'https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=hook_new&qyid='+val.id 
			this.form.name 		 = val.name || ''
            this.form.contract   = val.contract || ''
            this.form.mobile     = val.mobile || ''
            this.form.address	 = val.address || ''
            this.form.qyid 		 = val.id
            this.form.appid 	 = val.appid
            this.form.fromphone  = val.fromphone
            this.form.appsecret  = val.appsecret
            this.form.access_token = val.access_token
        }
    },

    methods: {
        changeInfo() {
            if(this.form.name && this.form.mobile && this.form.address) {
                changeUserinfo(this.form).then(res => {
                    if(res.code == '1'){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.getUserinfo()
					 
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        })
                    }
                })
            } else {
                this.$message({
                    message: this.$t('pleaseFillInTheInformation'),
                    type: 'warning'
                })
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.corporateInformation-wrapper {
    .btn{
        width: 80%;
        display: block;
        margin: 0 auto;
    }
}
</style>